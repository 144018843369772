
import { Vue, Options, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductData } from "@/domain/entities/Product";
class Props {
  modelValue = prop<any>({});
  placeholder = prop<string>({
    default: "Pilih tipe produk"
  });
  title = prop<string>({
    default: "Tipe Produk"
  });
  status = prop<string>({
    default: ""
  });
  disabled = prop<boolean>({
    default: false
  });
  disableBackground = prop<boolean>({
    default: false
  });
}

@Options({
  emits: ["update:modelValue", "change"]
})
export default class MultipleSelectProduct extends Vue.with(Props) {
  isLoadingProduct = true;
  listProduct: ProductData[] = [];
  fetchProduct = debounce(async (search: string) => {
    this.isLoadingProduct = true;
    const responseProduct = await ProductController.getProductList({
      page: 1,
      limit: 10,
      search: search,
      status: this.status,
      productCode: ""
    });
    this.listProduct = responseProduct.data;
    this.isLoadingProduct = false;
  }, 250);
  get optionProduct(): string[] {
    return this.listProduct.map((product: ProductData) => product.name);
  }
  onBlur() {
    this.isLoadingProduct = true;
  }
}
